import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { NavLink } from "react-router-dom";

export default function CusmtomBlock() {
    const { rpdata } = useContext(GlobalDataContext);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    return (
        <section className="w-full h-auto py-20 bg2 flex justify-center items-center">
            <div className="w-[80%] flex md:flex-row justify-between flex-col gap-5">
                <div className="md:w-[50%] w-full space-y-5">
                    <h2 className="text-white font-semibold" data-aos="zoom-out">{rpdata?.dbSlogan?.[10]?.slogan}</h2>
                    <p className="text-white text-[16px]" data-aos="zoom-out">{rpdata?.dbHome?.[2]?.text}</p>
                    <br />
                    <NavLink
                        to={
                            rpdata?.autoGntLandingFromService
                                ? `/${rpdata?.dbServices?.[0]?.name?.replaceAll(/[\^*@!"#$%&/()=?¡!¿'\\ ]/g, "-").toLowerCase()}`
                                : "/services"
                        }

                        onClick={goToTop}
                    >
                        <p className="items-center cursor-pointer text-white font-bold relative text-[14px] w-[9em] h-[3em] text-center bg-gradient-to-r from-red-500 from-10% via-green-500 via-30% to-pink-500 to-90% bg-[length:400%] rounded-[30px] z-10 hover:animate-gradient-xy hover:bg-[length:100%] before:content-[''] before:absolute before:-top-[5px] before:-bottom-[5px] before:-left-[5px] before:-right-[5px] before:bg-gradient-to-r before:from-violet-500 before:from-10% before:via-sky-500 before:via-30% before:to-pink-500 before:bg-[length:400%] before:-z-10 before:rounded-[35px] before:hover:blur-xl before:transition-all before:ease-in-out before:duration-[1s] before:hover:bg-[length:10%] active:bg-red-700 focus:ring-green-700"
                        >
                            View More
                        </p>
                    </NavLink>
                </div>
                <div className="md:w-[50%] w-full">
                    <div className="cardService1">
                        <div className="relative flex flex-col">
                            <img src={rpdata?.stock?.[34]} alt="img" className="relative mx-auto w-[95%] h-auto object-cover rounded-md z-[1]" />
                            <h2 >{rpdata?.dbServices?.[0]?.name}</h2>
                        </div>
                    </div>

                </div>

            </div>

        </section>
    );

}