import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Directories from "../components/Home/Directories";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import ServicesHome from "../components/Home/ContentServicesHome";
import HeroSection from "../components/Home/HeroSection";
import Paletacolor from "../components/global/Paletacolor";
import BlockPrincipal from "../components/block/Block_8";
import VideoPromocional from "../components/global/VideoPromocional";
import ContentServices from "../components/Services/ContentServices";
import GalleryContent from "../components/Gallery/GalleryContent";
import OtherBlock from "../components/block/Block_5";
import BlockTwelve from "../components/block/Block_12";
import CusmtomBlock from "../components/block/CustomBlock";


function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">
        {/* <Modal /> */}

        <HeroSection
          urlVideo={"https://firebasestorage.googleapis.com/v0/b/videos-de-stock.appspot.com/o/Roofing%2FRoofing%20Services.mp4?alt=media&token=c8130fbf-0cb4-4943-890d-ce48094c2361"}
          slogan={rpdata?.dbSlogan?.[0]?.slogan}
          text={rpdata?.dbValues?.[0]?.description}
          image1={rpdata?.gallery?.length > 3 ? rpdata?.gallery?.[3] : rpdata?.stock?.[0]}
          image2={rpdata?.gallery?.length > 4 ? rpdata?.gallery?.[4] : rpdata?.stock?.[4]}
          image3={rpdata?.gallery?.length > 5 ? rpdata?.gallery?.[5] : rpdata?.stock?.[2]}
          image4={rpdata?.gallery?.length > 6 ? rpdata?.gallery?.[6] : rpdata?.stock?.[5]}

        />
        <BlockPrincipal
          text={rpdata?.dbHome?.[0].text}
          image1={rpdata?.gallery?.length > 0 ? rpdata?.gallery?.[10] : rpdata?.stock?.[0]}
          image2={rpdata?.gallery?.length > 1 ? rpdata?.gallery?.[21] : rpdata?.stock?.[1]}
          image3={rpdata?.gallery?.length > 2 ? rpdata?.gallery?.[12] : rpdata?.stock?.[2]}
          sloganPrincipal={true}
          listsServices
        />
        <CusmtomBlock />
        {/* video promocional */}
        {rpdata?.videosPromo?.[0]?.activo ? (
          <VideoPromocional
            title={rpdata?.dbSlogan?.[2]?.slogan}
            text={rpdata?.dbAbout?.[1]?.text}
            linkVideo={`${rpdata?.videosPromo?.[0].link}`}
            image={`${rpdata?.videosPromo?.[0].img}`}

          />
        ) : null}
        <Directories />
        {/* <SlideLogo
        image={rpdata?.stock?.[39]}
        text={rpdata?.dbHome?.[2]?.text}
        /> */}

        <BlockTwelve
          image={rpdata?.gallery?.length > 4 ? rpdata?.gallery?.[4] : rpdata?.stock?.[4]}
          slogan={rpdata?.dbSlogan?.[5]?.slogan}
          text={rpdata?.dbHome?.[1]?.text}
        />
        <CounterUp image={rpdata?.gallery?.length > 5 ? rpdata?.gallery?.[5] : rpdata?.stock?.[5]} />
        <OtherBlock
          image={rpdata?.gallery?.length > 6 ? rpdata?.gallery?.[6] : rpdata?.stock?.[6]}
          text={rpdata?.dbAbout?.[0].text}
          title={rpdata?.dbSlogan?.[7].slogan}
          listsAbout
        />
        {/* our reviews */}
        {rpdata?.reviews?.isHomeOnly === true ? (
          <div className="w-4/5 mx-auto py-[100px]">
            <h1 className="pb-10 text-center">
              {rpdata?.labels?.general?.titleReviews}
            </h1>
            <div className={`${rpdata?.reviews?.links?.[0]}`}></div>
          </div>
        ) : null}

        {/* Servicios del home */}
        {rpdata?.simpleWidgets?.[3]?.activo ? (
          <div className="pt-20">
            <ContentServices />
          </div>
        ) : (
          <ServicesHome />
        )}

        {/* Gallery cuando es OnePages */}

        {/* Paleta de Colores */}
        {rpdata?.simpleWidgets?.[3]?.activo ? (
          <div className="pt-10 pb-28">
            <h2 className="text-center pb-[50px]">
              {rpdata?.labels?.general?.titleGallery}
            </h2>
            <GalleryContent
              galleryImages={
                rpdata?.stock?.length > 0 ? rpdata?.stock : rpdata?.stock
              }
            />
          </div>
        ) : null}
        {rpdata?.brandingExtra?.[0]?.activo ? <Paletacolor /> : null}
        <div
          className="bgFormHome"
          style={{ backgroundImage: `url("${rpdata?.gallery?.length > 7 ? rpdata?.gallery?.[7] : rpdata?.stock?.[7]}")` }}
        >
          <div className="relative">
            <FormHome />
          </div>
        </div>

        <Map />
      </div>
    </BaseLayout>
  );
}

export default Home;
